export const days = [
    { name: 'Monday', value: 1 },
    { name: 'Tuesday', value: 2 },
    { name: 'Wednesday', value: 3 },
    { name: 'Thursday', value: 4 },
    { name: 'Friday', value: 5 },
    { name: 'Saturday', value: 6 },
    { name: 'Sunday', value: 7 },
];

export const dateRange = {
    January: { range: 31, order: 1 },
    February: { range: 29, order: 2 },
    March: { range: 31, order: 3 },
    April: { range: 30, order: 4 },
    May: { range: 31, order: 5 },
    June: { range: 30, order: 6 },
    July: { range: 31, order: 7 },
    August: { range: 31, order: 8 },
    September: { range: 30, order: 9 },
    October: { range: 31, order: 10 },
    November: { range: 30, order: 11 },
    December: { range: 31, order: 12 },
};

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
