/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import api from '../../api';
import 'react-widgets/scss/styles.scss';
import { Controller, useForm } from 'react-hook-form';
import { Form, Row, Col, Button, Spinner, Container } from 'react-bootstrap';
import Dropdown from '../form-controls/Dropdown';
import { DISTRIBUTION, FRESHNESS, VOLUME } from '../../helper/variables';
import { useNavigate } from 'react-router-dom';
import Input from '../form-controls/Input';
import useMasterConfiguration from './useEditMasterConfiguration';
import { useParams } from 'react-router-dom';
import ContainerLoader from '../loader/container-loader';
import { FaArrowLeft } from 'react-icons/fa';
import { stringTypes } from '../../utils/dataTypes';
import ConfirmationModal from '../confirmatinModal/confirmationModal';
import { handleOnSubmit } from './submit';
import { dateRange, days, months } from '../add-masterConfiguration/contants';
import { getDateBasedOnDayVal } from '../../helper/calculateDayoftheyear';

export default function EditMasterConfiguration() {
    const { id } = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting, dirtyFields },
        reset,
        setValue,
    } = useForm({
        reValidateMode: 'onChange',
    });

    const [list, setList] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [tempdata, setTempdata] = useState();

    const defaultValues = {
        table_name: '',
        column_name: '',
        check_type: '',
        group_by_col: '',
        date_field: '',
        frequency: '',
        run_day: '',
        run_time: '00:00:00',
        latency: '0',
        agg_type: '',
        additional_threshold: '0',
        minimum_threshold: '0',
        month: '',
        date: '',
    };

    const navigate = useNavigate();

    const {
        sourceListData,
        sourceListByTableName,
        dateColums,
        customErrors,
        setCustomErrors,
        isLoading,
        setIsLoading,
        setTableName,
        attribute,
        setLoadGroupBy,
        setAttribute,
        checkType,
        setCheckType,
        globalLoader,
        month,
        setMonth,
        frequency,
        setFrequency,
        loadMinThreshold,
        setLoadMinThreshold,
    } = useMasterConfiguration();

    const handleClearTableNameRelated = () => {
        setValue('column_name', '');
        setValue('group_by_col', '');
        setValue('date_field', '');
        setValue('additional_threshold', '');
    };

    const handleClearFrequencyRelated = () => {
        setValue('run_day', '');
        setValue('month', '');
        setMonth('');
        setValue('date', '');
    };
    const handleClear = () => {
        reset(defaultValues);
        setCustomErrors({});
        setLoadGroupBy(false);
    };

    const getRundayVal = (val) => {
        if (frequency === 'Weekly') return val?.value;
        if (frequency === 'Monthly') return val;
    };

    const attributeData = () => {
        let data = [];
        if ([VOLUME].includes(checkType)) {
            sourceListByTableName?.forEach(({ column_name, data_type }) => {
                if (stringTypes.includes(data_type)) {
                    data.push({
                        data_type,
                        column_name: `${column_name} - count`,
                    });
                    data.push({
                        data_type,
                        column_name: `${column_name} - distinct`,
                    });
                    return;
                }
                data.push({
                    data_type,
                    column_name: `${column_name} - count`,
                });
                data.push({
                    data_type,
                    column_name: `${column_name} - sum`,
                });
            });
            return data;
        }
        return sourceListByTableName;
    };

    const modalSubmit = async (data) => {
        handleOnSubmit(
            data,
            id,
            checkType,
            setCustomErrors,
            navigate,
            attribute,
            dirtyFields,
        );
    };

    const globalSubmit = async (data) => {
        const temp = Object.keys(dirtyFields);
        if (
            temp.includes('column_name') ||
            temp.includes('table_name') ||
            temp.includes('date_field') ||
            temp.includes('frequency')
        ) {
            setModalShow(true);
        } else {
            handleOnSubmit(
                data,
                id,
                checkType,
                setCustomErrors,
                navigate,
                attribute,
                dirtyFields,
            );
        }
    };

    useEffect(() => {
        const fetchSourceListByTableName = async () => {
            try {
                setIsLoading(true);
                const sourceList = await api.crud.get({
                    resource: 'api/masterConfiguration',
                    resourceId: id,
                });
                setList(sourceList);

                if (
                    sourceList.data.data.minimum_threshold >= 0 &&
                    sourceList.data.data.check_type === VOLUME
                ) {
                    setValue(
                        'minimum_threshold',
                        sourceList.data.data.minimum_threshold,
                    );
                    setLoadMinThreshold(true);
                }

                setValue('table_name', sourceList.data.data.table_name);
                setValue('column_name', sourceList.data.data.column_name);
                setValue('group_by_col', sourceList.data.data.group_by_col);
                setValue('date_field', sourceList.data.data.date_field);
                setValue('run_time', sourceList.data.data.run_time);
                setValue('frequency', sourceList.data.data.frequency);
                setFrequency(sourceList.data.data.frequency);
                setValue('latency', sourceList.data.data.latency);
                setValue('run_day', sourceList.data.data.run_day);
                if (sourceList.data.data.frequency === 'Yearly') {
                    let d = getDateBasedOnDayVal(sourceList.data.data.run_day);
                    let month = d.get('month');
                    let date = d.get('date');
                    // let newDate = moment();
                    // let day = newDate.day(date).format('dddd');
                    setValue('month', months[month]);
                    setMonth(months[month]);
                    setValue('date', date);
                }
                setValue(
                    'additional_threshold',
                    sourceList.data.data.additional_threshold,
                );

                setTableName(sourceList.data.data.table_name);
                setCheckType(sourceList.data.data.check_type);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSourceListByTableName();
    }, [id]);

    return (
        <Container>
            <ConfirmationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                heading={'Warning'}
                body={
                    'Current KPI Data will be deleted and reloaded as per the revised KPI'
                }
                success={'Add/Delete'}
                failure={'Cancel'}
                action={() => {
                    modalSubmit(tempdata);
                }}
            ></ConfirmationModal>
            {isLoading ? (
                <ContainerLoader></ContainerLoader>
            ) : (
                <>
                    <div className="d-flex justify-content-center align-items-center my-5">
                        <div
                            className="rounded-5 mx-2 my-4 p-4 w-75 position-relative"
                            style={{
                                boxShadow: 'rgba(0,0,0,0.2) 0px 2px 8px 0px',
                                backgroundColor: 'whitesmoke',
                                marginLeft: '12.5%',
                            }}
                        >
                            {globalLoader && (
                                <Spinner
                                    className="position-absolute opacity-100 top-50 start-50"
                                    style={{ zIndex: 1 }}
                                ></Spinner>
                            )}
                            <h4
                                className={`fw-bold  ms-4 text-center  ${
                                    globalLoader ? `opacity-50` : null
                                }`}
                            >
                                Update KPI
                            </h4>
                            <div
                                className="d-flex justify-content-end position-absolute"
                                style={{
                                    right: '5%',
                                    cursor: 'pointer',
                                    position: 'static',
                                }}
                            >
                                <Button
                                    variant="dark"
                                    disabled={globalLoader}
                                    size="sm"
                                    onClick={() => navigate('/')}
                                >
                                    <FaArrowLeft /> Back
                                </Button>
                            </div>

                            <Form
                                onSubmit={handleSubmit((data) => {
                                    globalSubmit(data); // dateColums?.length, // validate date field , it will return true or false with integer representation
                                    setTempdata(data);
                                })}
                                className={`  ${
                                    globalLoader ? `opacity-50` : null
                                }`}
                            >
                                <Row className="mb-4">
                                    <Col xs={4}>
                                        Check type -{' '}
                                        <div
                                            className="fw-bold text-success"
                                            style={{ fontSize: '27px' }}
                                        >
                                            {(checkType === 'schema' &&
                                                'Data Structure') ||
                                                (checkType === 'distribution' &&
                                                    'Distribution') ||
                                                (checkType === 'freshness' &&
                                                    'Timeliness') ||
                                                (checkType === 'volume' &&
                                                    'KPI Monitoring ')}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} md={6}>
                                        <Controller
                                            control={control}
                                            name="table_name"
                                            rules={{
                                                required: true,
                                            }}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Dropdown
                                                    id="table_name"
                                                    label="Source"
                                                    busy={
                                                        sourceListData.length >
                                                        0
                                                            ? false
                                                            : true
                                                    }
                                                    filter="contains"
                                                    value={value}
                                                    data={sourceListData}
                                                    dataKey="table_name"
                                                    textField="table_name"
                                                    defaultValue={value}
                                                    onChange={(value) => {
                                                        onChange(
                                                            value?.table_name,
                                                        );
                                                        setTableName(
                                                            value?.table_name,
                                                        );
                                                        handleClearTableNameRelated();
                                                    }}
                                                    error={
                                                        !errors.table_name
                                                            ? ''
                                                            : errors.table_name
                                                                  ?.type ===
                                                              'required'
                                                            ? 'Table Name is required'
                                                            : 'Invalid Table Name'
                                                    }
                                                />
                                            )}
                                        />
                                    </Col>

                                    {[VOLUME, DISTRIBUTION].includes(
                                        checkType,
                                    ) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="column_name"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Dropdown
                                                        label="Attribute"
                                                        busy={
                                                            sourceListByTableName?.length >
                                                            0
                                                                ? false
                                                                : 'No data matches'
                                                        }
                                                        // disabled={
                                                        //     !sourceListByTableName.length ||
                                                        //     !checkType
                                                        // }
                                                        filter="contains"
                                                        value={value}
                                                        defaultValue={value}
                                                        data={attributeData()}
                                                        dataKey="column_name"
                                                        textField="column_name"
                                                        onChange={(value) => {
                                                            onChange(
                                                                value?.column_name,
                                                            );
                                                            setAttribute(value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}
                                </Row>

                                {/* <Row className="mb-3">
                                    {checkType === VOLUME && (
                                        <Col>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={loadMinThreshold}
                                                    onChange={() => {
                                                        setLoadMinThreshold(
                                                            (pre) => {
                                                                if (!pre)
                                                                    setValue(
                                                                        'minimum_threshold',
                                                                        '0',
                                                                    );
                                                                return !pre;
                                                            },
                                                        );
                                                    }}
                                                />
                                                <span>
                                                    &nbsp;&nbsp; Minimum
                                                    Threshold
                                                </span>
                                            </div>
                                        </Col>
                                    )}
                                </Row> */}

                                <Row>
                                    {[FRESHNESS, VOLUME, DISTRIBUTION].includes(
                                        checkType,
                                    ) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="date_field"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Dropdown
                                                        label="Date Field"
                                                        // disabled={
                                                        //     !dateColums.length ||
                                                        //     !checkType
                                                        // }
                                                        filter="contains"
                                                        value={value}
                                                        defaultValue={value}
                                                        data={dateColums}
                                                        dataKey="column_name"
                                                        textField="column_name"
                                                        onChange={(value) => {
                                                            onChange(
                                                                value?.column_name,
                                                            );
                                                            setAttribute(value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}
                                    {[VOLUME].includes(checkType) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="group_by_col"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Dropdown
                                                        label="Group By"
                                                        // disabled={
                                                        //     !sourceListByTableName.length ||
                                                        //     !checkType ||
                                                        //     !attribute
                                                        // }
                                                        filter="contains"
                                                        value={value}
                                                        defaultValue={value}
                                                        data={sourceListByTableName?.filter(
                                                            ({
                                                                column_name,
                                                                data_type,
                                                            }) =>
                                                                !attribute?.column_name?.startsWith(
                                                                    column_name,
                                                                ) &&
                                                                stringTypes.includes(
                                                                    data_type,
                                                                ),
                                                        )}
                                                        dataKey="column_name"
                                                        textField="column_name"
                                                        onChange={(value) => {
                                                            onChange(
                                                                value?.column_name,
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}
                                    {[FRESHNESS].includes(checkType) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="frequency"
                                                // rules={{ required: true }}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        value,
                                                        name,
                                                    },
                                                }) => (
                                                    <Dropdown
                                                        label="Frequency"
                                                        data={[
                                                            'Daily',
                                                            'Weekly',
                                                            'Monthly',
                                                            'Yearly',
                                                        ]}
                                                        value={value}
                                                        filter="contains"
                                                        // isMandatory={true}
                                                        defaultValue={value}
                                                        dataKey="table_name"
                                                        textField="table_name"
                                                        onChange={(value) => {
                                                            onChange(value);
                                                            setFrequency(value);
                                                            handleClearFrequencyRelated();
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}

                                    {[FRESHNESS].includes(checkType) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="latency"
                                                // rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        label="Latency(in Days)"
                                                        defaultValue={
                                                            list.data.data
                                                                .latency
                                                        }
                                                        type="number"
                                                        // isMandatory={true}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}

                                    {[FRESHNESS].includes(checkType) &&
                                        (frequency === 'Weekly' ||
                                            frequency === 'Daily' ||
                                            !frequency) && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="run_day"
                                                    // rules={{ required: true }}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }) => {
                                                        return (
                                                            <Dropdown
                                                                label="Run day"
                                                                filter="contains"
                                                                value={value}
                                                                data={days}
                                                                dataKey="name"
                                                                textField="name"
                                                                disabled={
                                                                    !frequency ||
                                                                    frequency ===
                                                                        'Daily'
                                                                }
                                                                onChange={(
                                                                    value,
                                                                ) => {
                                                                    onChange(
                                                                        getRundayVal(
                                                                            value,
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}

                                    {[FRESHNESS].includes(checkType) &&
                                        frequency === 'Monthly' && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="run_day"
                                                    // rules={{ required: true }}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }) => {
                                                        return (
                                                            <Dropdown
                                                                label="Run day"
                                                                filter="contains"
                                                                value={value}
                                                                data={Array.from(
                                                                    {
                                                                        length: 31,
                                                                    },
                                                                ).map(
                                                                    (o, i) =>
                                                                        i + 1,
                                                                )}
                                                                onChange={(
                                                                    value,
                                                                ) => {
                                                                    onChange(
                                                                        getRundayVal(
                                                                            value,
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}

                                    {[FRESHNESS].includes(checkType) &&
                                        frequency === 'Yearly' && (
                                            <Col sm={12} md={6}>
                                                <Row>
                                                    <Col>
                                                        <Controller
                                                            control={control}
                                                            name="month"
                                                            // rules={{ required: true }}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => {
                                                                return (
                                                                    <Dropdown
                                                                        label="Run day"
                                                                        filter="contains"
                                                                        defaultValue="January"
                                                                        value={
                                                                            value
                                                                        }
                                                                        data={
                                                                            months
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            setMonth(
                                                                                value,
                                                                            );
                                                                            onChange(
                                                                                value,
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Controller
                                                            control={control}
                                                            name="date"
                                                            // rules={{ required: true }}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => {
                                                                return (
                                                                    <Dropdown
                                                                        label=""
                                                                        className="mt-2"
                                                                        disabled={
                                                                            !month
                                                                        }
                                                                        filter="contains"
                                                                        value={
                                                                            value
                                                                        }
                                                                        data={Array.from(
                                                                            {
                                                                                length: dateRange[
                                                                                    month
                                                                                ]
                                                                                    ?.range,
                                                                            },
                                                                        )?.map(
                                                                            (
                                                                                o,
                                                                                i,
                                                                            ) =>
                                                                                i +
                                                                                1,
                                                                        )}
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            onChange(
                                                                                value,
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}

                                    {[FRESHNESS].includes(checkType) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="run_time"
                                                // rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type="time"
                                                        defaultValue={
                                                            list.data.data
                                                                .run_time
                                                        }
                                                        label="Run Time"

                                                        // isMandatory={true}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}
                                    {[VOLUME, DISTRIBUTION].includes(
                                        checkType,
                                    ) && (
                                        <Col sm={12} md={6}>
                                            <Controller
                                                control={control}
                                                name="column_name"
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Controller
                                                        control={control}
                                                        name="additional_threshold"
                                                        // rules={{ required: true }}
                                                        render={({ field }) => {
                                                            return (
                                                                <Input
                                                                    {...field}
                                                                    type="number"
                                                                    label="Additional Threshold(In Percentage)"
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    )}
                                    {/* {[VOLUME].includes(checkType) &&
                                        loadMinThreshold && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="minimum_threshold"
                                                    // rules={{ required: true }}
                                                    render={({
                                                        field,
                                                        field: { value },
                                                    }) => {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                value={value}
                                                                type="number"
                                                                label="Minimum Threshold"
                                                            />
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )} */}
                                </Row>

                                <Row>
                                    <Col>
                                        {Object.values(customErrors).map(
                                            (error) => (
                                                <ul>
                                                    <li className="text-danger">
                                                        {error}
                                                    </li>
                                                </ul>
                                            ),
                                        )}
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                type="button"
                                                className="btn btn-secondary  mt-4 me-4"
                                                onClick={handleClear}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="btn btn-success mt-4 me-4"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? (
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        style={{
                                                            height: '15px',
                                                            width: '15px',
                                                        }}
                                                    />
                                                ) : (
                                                    'Save'
                                                )}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </>
            )}
        </Container>
    );
}
