import { toastr } from 'react-redux-toastr';
import * as http from './http';
import ApiService from './service';

export class SourceListService extends ApiService {
    async getSourceList(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        let response = await await http.get(url, this.store);
        if (response?.data?.status !== 200) {
            toastr.error('Error', response?.data?.message);
            return null;
        }
        return { data: response.data };
    }

    async getSourceListbyTableName(request) {
        let { stringColOnly, resource, tableName } = request;
        const url = `${this.apiDomain}/${resource}/${tableName}`;
        let data = await http.post(url, { stringColOnly }, this.store);
        if (data?.data?.status !== 200) {
            toastr.error('Error', data?.data?.message);
            return null;
        }
        return data;
    }

    async getDateColumnsByTableName(request) {
        try {
            const url = `${this.apiDomain}${request.resource}`;
            let {
                data,
                data: { status, message },
            } = await http.get(url, this.store);
            if (status !== 200) {
                toastr.error('Error', message);
                return null;
            }
            return data;
        } catch (error) {
            console.log(error.message);
        }
    }
}
