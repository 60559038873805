export const RouteKeys = {
    Login: '/auth/login',
    Home: '/',
    NotFound: '/page-not-found',
    Metrics: 'metrics',
    SourceList: 'sourceList',
    Auth: '/auth',
    AzureLogin: '/auth/aad-redirect',
    AddUser: '/users',
    Themes: 'themes',
    AddMetrics: '/addMetrics',
    EditMetrics: '/editMetrics/:id',
    CreateUser: '/addUser',
    EditUser: '/edituser/:id',
    AddVariable: '/addSource',
    Dashboard: '/dashboard/:checktype',
    Volume: 'anamoly/:id',
    AddConfigurationSettings: '/addConfigurationSettings',
    DataSource: '/dataSource',
    SchemaAnamoly: '/anamoly/schema/:id/:anamoly_id',
    DistributionAnamoly: 'anamoly/distribution/:id',
    ViewDataSource: 'datasource/:secret',
    Qlik: '/qlik/:appId/:sheetId',
    Freshness: '/reports/freshness',
    Analysis: '/reports/volume',
    Distribution: '/reports/distribution',
    Schema:'/reports/schema'
};
