import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContainerLoader from '../../components/loader/container-loader';
import AzureLogin from '../auth/AzureLogin';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import AddUser from '../../components/add-user/addUser';
import Themes from '../../components/themes/themes';
import EditMasterConfiguration from '../../components/edit-masterConfiguration/edit-masterConfiguration';
import DataSource from '../../components/table/data-source/datasource';

const AddVariable = lazy(() => import('../../components/keyPairs/addVariable'));
const AddMasterConfiguration = lazy(() =>
    import('../../components/add-masterConfiguration/add-masterConfiguration'),
);
const Home = lazy(() => import('../home'));
const NotFound = lazy(() => import('../notfound'));
const AuthPage = lazy(() => import('../auth'));
const CreateUser = lazy(() => import('../../components/Crud/CreateUser'));
const EditUser = lazy(() => import('../../components/Crud/EditUser'));
const ViewDataSource = lazy(() =>
    import('../../components/ViewDataSource/viewDataSource'),
);

const AddConfigurationSettings = lazy(() =>
    import('../../components/add-configurationSettings'),
);
const Dashboard = lazy(() => import('../dashboard/Dashboard'));

const Freshness = lazy(()=> import('../../components/reports/freshness'));

const Analysis = lazy(()=> import('../../components/reports/analysis'));


const Distribution = lazy(()=> import('../../components/reports/distribution'));

const Schema = lazy(()=> import('../../components/reports/schema'));

const Qlik = lazy(() => import('../qlikDashboard/qlikDashboard'));

const Volume = lazy(() => import('../../components/volume/volume'));

const SchemaAnamoly = lazy(() =>
    import('../../components/schema_anamoly/schema_anamoly'),
);

const DistributionAnamoly = lazy(() =>
    import('../../components/distribution_schema/distribution_schema'),
);

class NavRoutes extends React.Component {
    render() {
        return (
            <Suspense fallback={<ContainerLoader />}>
                <Routes>
                    <>
                        <Route
                            path={RouteKeys.Home}
                            element={
                                <AuthenticateRoute>
                                    <Home />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ViewDataSource}
                            element={
                                <AuthenticateRoute>
                                    <ViewDataSource />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddConfigurationSettings}
                            element={
                                <AuthenticateRoute>
                                    <AddConfigurationSettings />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Metrics}
                            element={
                                <AuthenticateRoute>
                                    <Home />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Themes}
                            element={
                                <AuthenticateRoute>
                                    <Themes />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddUser}
                            element={
                                <AuthenticateRoute>
                                    <AddUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Volume}
                            element={
                                <AuthenticateRoute>
                                    <Volume />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.SchemaAnamoly}
                            element={
                                <AuthenticateRoute>
                                    <SchemaAnamoly />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.DistributionAnamoly}
                            element={
                                <AuthenticateRoute>
                                    <DistributionAnamoly />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Auth}
                            element={
                                <RedirectIfAuthenticatedRoute>
                                    <AuthPage />
                                </RedirectIfAuthenticatedRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AzureLogin}
                            element={
                                <RedirectIfAuthenticatedRoute>
                                    <AzureLogin />
                                </RedirectIfAuthenticatedRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddMetrics}
                            element={
                                <AuthenticateRoute>
                                    <AddMasterConfiguration />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EditMetrics}
                            element={
                                <AuthenticateRoute>
                                    <EditMasterConfiguration />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.CreateUser}
                            element={
                                <AuthenticateRoute>
                                    <CreateUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EditUser}
                            element={
                                <AuthenticateRoute>
                                    <EditUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddVariable}
                            element={
                                <AuthenticateRoute>
                                    <AddVariable />
                                </AuthenticateRoute>
                            }
                        />

                        <Route
                            path={RouteKeys.Dashboard}
                            element={
                                <AuthenticateRoute>
                                    <Dashboard />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.DataSource}
                            element={
                                <AuthenticateRoute>
                                    <DataSource />
                                </AuthenticateRoute>
                            }
                        />
                         <Route
                            path={RouteKeys.Freshness}
                            element={
                                <AuthenticateRoute>
                                    <Freshness />                               
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Schema}
                            element={
                                <AuthenticateRoute>
                                    <Schema />                               
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Analysis}
                            element={
                                <AuthenticateRoute>
                                  <Analysis />                            
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Distribution}
                            element={
                                <AuthenticateRoute>
                                  <Distribution />                              
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Qlik}
                            element={
                                <AuthenticateRoute>
                                    <Qlik />
                                </AuthenticateRoute>
                            }
                        />
                        <Route path={RouteKeys.NotFound} element={NotFound} />
                        <Route path="*" element={NotFound} />
                    </>
                </Routes>
            </Suspense>
        );
    }
}

export default NavRoutes;
