import moment from 'moment';

export const calculateDayOftheyear = (date) => {
    try {
        if (!date) return null;
        let m = moment();
        m.set({
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
        });
        return m.dayOfYear();
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getDateBasedOnDayVal = (val) => {
    let m = moment();
    return m.dayOfYear(val);
};
